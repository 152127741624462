type StreamListener = (stream: MediaStream) => void;

type StreamConstraints = MediaStreamConstraints & { video: { facingMode: { exact: string } } };

const Modes = ['user', 'environment'];

class Camera {
  listeners: Array<StreamListener> = [];

  devices?: MediaDeviceInfo[];

  currentModeIndex = 0;

  stream?: MediaStream;

  streamParams?: StreamConstraints;

  streamParamHash?: string;

  static instance?: Camera;

  static getInstance() {
    if (!Camera.instance) {
      Camera.instance = new Camera();
    }
    (window as any).camera = Camera.instance;
    return Camera.instance;
  }

  unsubscribe(listener: StreamListener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  subscribe(listener: StreamListener) {
    this.listeners.push(listener);
  }

  protected async retrieveDevices() {
    const mediaDevices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
    this.devices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
  }

  async start() {
    if (this.devices === undefined) {
      await this.retrieveDevices();
    }
    if (!this.devices) {
      throw new Error('no camera detected');
    }
    if (this.stream) return;
    this.openStream({
      audio: false,
      video: {
        width: 1080,
        height: 1080,
        facingMode: {
          exact: Modes[this.currentModeIndex],
        },
        frameRate: {
          ideal: 30,
        },
      },
    });
  }

  async openStream(constraints: StreamConstraints) {
    const contraintsHash = JSON.stringify(constraints);

    console.log(constraints, this.streamParams);

    if (contraintsHash !== this.streamParamHash) {
      if (this.streamParams?.video.facingMode.exact !== constraints.video.facingMode.exact) {
        this.stop();
      }
      this.streamParamHash = contraintsHash;
      this.streamParams = constraints;
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log(this.stream);
      this.listeners.forEach((listener) => this.stream && listener(this.stream));
    }
  }

  stop() {
    if (!this.stream) return;
    console.log('stop stream');

    this.stream.getTracks().forEach((track, i) => {
      console.log('stop', i);
      track.stop();
      track.enabled = false;
      if (this.stream) this.stream.removeTrack(track);
    });
    this.stream = undefined;
    this.streamParamHash = undefined;
  }

  isMirror() {
    return this.stream && this.stream.getVideoTracks()[0].getSettings().facingMode === 'user';
  }

  switchDevice() {
    if (!this.devices) return;
    const currentDeviceIndex = (this.currentModeIndex + 1) % this.devices.length;
    const params = JSON.parse(JSON.stringify(this.streamParams));
    if (!params) return;
    this.currentModeIndex = currentDeviceIndex;
    params.video.facingMode.exact = Modes[this.currentModeIndex];
    this.openStream(params);
  }
}

export default Camera;
